import anime from 'animejs/lib/anime.es.js';

export function startAnimation () { 
    setTimeout(() => {
        anime ({
            targets: '.animation-line.top, .animation-line.bottom',
            width: '0px',
            easing: 'easeInOutExpo',
            direction: 'alternate',
            duration: 3000,
            loop: true
        })

        anime ({
            targets: '.animation-line.right, .animation-line.left',
            height: '0px',
            easing: 'easeInOutExpo',
            direction: 'alternate',
            duration: 3000,
            loop: true
        })

        anime ({
            targets: '.animation-line.secondary-slides',
            width: '100%',
            easing: 'easeInOutExpo',
            direction: 'alternate',
            duration: 3000,
            loop: true
        })
    }, 500);
}

startAnimation()