// tab_controller.js
import { Controller } from "stimulus"
import * as slideshowAnimation from '../packs/slideshow_anime.js'

export default class extends Controller {

    connect() {
        document.getElementById('bio').style.height = document.getElementById('photos').offsetHeight + 'px'
    }

    reloadLocation() {
        $( ".slideshow-container" ).load(window.location.href + " .slideshow-container" );
        slideshowAnimation.startAnimation()
    }

}